function parseColors(text) {
  text = text.replace(/([&§])0/gi, '</span>&r<span class="colour-0">');
  text = text.replace(/([&§])1/gi, '</span>&r<span class="colour-1">');
  text = text.replace(/([&§])2/gi, '</span>&r<span class="colour-2">');
  text = text.replace(/([&§])3/gi, '</span>&r<span class="colour-3">');
  text = text.replace(/([&§])4/gi, '</span>&r<span class="colour-4">');
  text = text.replace(/([&§])5/gi, '</span>&r<span class="colour-5">');
  text = text.replace(/([&§])6/gi, '</span>&r<span class="colour-6">');
  text = text.replace(/([&§])7/gi, '</span>&r<span class="colour-7">');
  text = text.replace(/([&§])8/gi, '</span>&r<span class="colour-8">');
  text = text.replace(/([&§])9/gi, '</span>&r<span class="colour-9">');
  text = text.replace(/([&§])a/gi, '</span>&r<span class="colour-a">');
  text = text.replace(/([&§])b/gi, '</span>&r<span class="colour-b">');
  text = text.replace(/([&§])c/gi, '</span>&r<span class="colour-c">');
  text = text.replace(/([&§])d/gi, '</span>&r<span class="colour-d">');
  text = text.replace(/([&§])e/gi, '</span>&r<span class="colour-e">');
  text = text.replace(/([&§])f/gi, '</span>&r<span class="colour-f">');
  return text;
}

function parseFormatting(text) {
  text = text.replace(/([&§])l(?!t)/gi, '<span class="format-bold">');
  text = text.replace(/([&§])o/gi, '<span class="format-italic">');
  text = text.replace(/([&§])m/gi, '<span class="format-line-through">');
  text = text.replace(/([&§])n/gi, '<span class="format-underline">');
  text = text.replace(/([&§])k/gi, '<span class=\'obfuscated\'>');
  return text;
}

function parseIcons(text) {
  text = text.replace(//gi, '<img class=\'emoji-preview\' src=\'/images/emoticons/food.png\' />');
  text = text.replace(//gi, '<img class=\'emoji-preview\' src=\'/images/emoticons/armour.png\' />');
  text = text.replace(//gi, '<img class=\'emoji-preview\' src=\'/images/emoticons/minecoin.png\' />');
  text = text.replace(//gi, '<img class=\'emoji-preview\' src=\'/images/emoticons/code-builder.png\' />');
  text = text.replace(//gi, '<img class=\'emoji-preview\' src=\'/images/emoticons/nintendo-minus.png\' />');
  text = text.replace(//gi, '<img class=\'emoji-preview\' src=\'/images/emoticons/nintendo-plus.png\' />');
  text = text.replace(//gi, '<img class=\'emoji-preview\' src=\'/images/emoticons/jump-button.png\' />');
  text = text.replace(//gi, '<img class=\'emoji-preview\' src=\'/images/emoticons/crouch-button.png\' />');
  text = text.replace(//gi, '<img class=\'emoji-preview\' src=\'/images/emoticons/ps-d-up.png\' />');
  text = text.replace(//gi, '<img class=\'emoji-preview\' src=\'/images/emoticons/ps-d-down.png\' />');
  text = text.replace(//gi, '<img class=\'emoji-preview\' src=\'/images/emoticons/ps-d-left.png\' />');
  text = text.replace(//gi, '<img class=\'emoji-preview\' src=\'/images/emoticons/ps-d-right.png\' />');
  text = text.replace(//gi, '<img class=\'emoji-preview\' src=\'/images/emoticons/nintendo-d-up.png\' />');
  text = text.replace(//gi, '<img class=\'emoji-preview\' src=\'/images/emoticons/nintendo-d-down.png\' />');
  text = text.replace(//gi, '<img class=\'emoji-preview\' src=\'/images/emoticons/nintendo-d-left.png\' />');
  text = text.replace(//gi, '<img class=\'emoji-preview\' src=\'/images/emoticons/nintendo-d-right.png\' />');
  text = text.replace(//gi, '<img class=\'emoji-preview\' src=\'/images/emoticons/xbox-a.png\' />');
  text = text.replace(//gi, '<img class=\'emoji-preview\' src=\'/images/emoticons/xbox-b.png\' />');
  text = text.replace(//gi, '<img class=\'emoji-preview\' src=\'/images/emoticons/xbox-x.png\' />');
  text = text.replace(//gi, '<img class=\'emoji-preview\' src=\'/images/emoticons/xbox-y.png\' />');
  text = text.replace(//gi, '<img class=\'emoji-preview\' src=\'/images/emoticons/ps-x.png\' />');
  text = text.replace(//gi, '<img class=\'emoji-preview\' src=\'/images/emoticons/ps-o.png\' />');
  text = text.replace(//gi, '<img class=\'emoji-preview\' src=\'/images/emoticons/ps-square.png\' />');
  text = text.replace(//gi, '<img class=\'emoji-preview\' src=\'/images/emoticons/ps-triangle.png\' />');
  return text;
}

function convertColorCodesToHTML(text) {
  if (typeof text !== 'string') {
    return '';
  }
  text = text.replace(/</gi, '&lt;');
  text = text.replace(/>/gi, '&gt;');
  text = text.replace(/\\n/gi, '&r<br />');
  text = text.replace(/\n/gi, '&r<br />');

  // Colours and Formatting
  text = parseColors(text);
  text = parseFormatting(text);
  text = parseIcons(text);

  // reset
  text = text.replace(/([&§])r/gi,
    '</span></span></span></span></span></span></span></span></span></span></span></span></span>'
    + '</span></span></span></span></span></span></span></span></span></span></span></span></span></span></span>'
    + '</span></span></span></span></span></span></span></span></span></span></span></span>');

  return text;
}

export {
  convertColorCodesToHTML,
  parseIcons,
};
