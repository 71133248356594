<template>
  <div class="min-vh-100 d-flex align-items-center justify-content-center">
    <div class="container"
         v-if="!store.editor.ready">
      <div class="row">
        <div class="col-10 offset-1 session-display" v-if="hasSession">
          <h1 class="bg-none display-1">Continue?</h1>
          <p class="my-4 display-6">
            You have an existing session translating
            <span class="border-info text-info px-3 py-2 border rounded">
                <fa icon="globe" class="mr-2"/>
                {{ getLangCode }}
              </span>
            would you like to continue that session?
          </p>
          <div class="d-flex align-items-center flex-row mt-4">
            <button @click="continueTranslation" class="btn btn-lg"
                    :class="{
                'btn-outline-warning': !isReadyToTranslate,
                'btn-outline-info': mode === 'dark' && isReadyToTranslate,
                'btn-info': mode === 'light' && isReadyToTranslate,
                }"
                    :disabled="!isReadyToTranslate">
              <fa icon="share" class="mr-2"/>
              Continue Translating
            </button>
            <span class="p-4">
                or
              </span>
            <button @click="newTranslation" class="btn btn-lg"
                    :class="{
                'btn-outline-warning': mode === 'dark',
                'btn-warning': mode === 'light',
              }">
              <fa icon="redo" class="mr-2"/>
              Start Over
            </button>
          </div>
        </div>
        <div class="col-10 offset-1" v-else>
          <div>

            <h2 class="bg-none m-0 py-4">Upload Your Translations Below</h2>
            <div class="card-body">
              <div class="flex flex-row d-flex justify-content-between">
                <TranslationDropBox lang="english" @fileUpload="addFile" :source="true"/>
                <TranslationDropBox lang="locale" @fileUpload="addFile"/>
              </div>

              <div>
                <div class="form-check">
                  <input type="checkbox" id="use_blank" class="form-check-input"
                         v-model="formCreation.use_blank"/>
                  <label for="use_blank" class="form-check-label small">
                    Use blank template as locale (Used when you don't have the optional
                    translation file)
                  </label>
                  <p v-if="store.locale.cached" class="text-danger">
                    Requires locale to not be cached, click below to clear cache
                  </p>
                </div>
                <div class="col-4">

                  <div class="form-group">
                    <select
                        class="form-select w-100"
                        v-model="formCreation.locale"
                        v-if="formCreation.use_blank">
                      <option value="" disabled>--- Choose language ---</option>
                      <option v-for="(value, index) in getPossibleLangCodes"
                              :value="value.localeCode" :key="index">
                        {{ value.displayName }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <button @click="beginTranslation" class="btn btn-outline-info mt-4"
                  :class="{'btn-outline-warning': !isReadyToTranslate}"
                  :disabled="!isReadyToTranslate">
            Start Translation
          </button>

        </div>
      </div>
      <div class="row">
        <div class="col-10 offset-1 mt-5">
          <button @click="clearCache" class="btn mt-5"
                  :class="{
              'btn-outline-danger': mode === 'dark',
              'btn-danger': mode === 'light',
            }">
            <fa icon="trash-alt"/>
            Clear Cache
          </button>
        </div>
      </div>
    </div>

    <div v-else class="translator-grid">
      <div id="layout-grid-navigation" class="d-block">
        <ul class="list-unstyled d-flex flex-row w-100 text-center dark-light-mode">
          <li class="w-50" @click="setMode('light')"
              :class="{'active': mode !== 'dark'}">
            <fa icon="sun" class="fa-2x m-2"/>
          </li>
          <li class="w-50" @click="setMode('dark')"
              :class="{'active': mode === 'dark'}">
            <fa icon="moon" class="fa-2x m-2"/>
          </li>
        </ul>
        <ul class="list-unstyled">
          <NavigationTree :translation="getSource"/>

          <li @click="clearCache" class="btn btn-outline-danger text-center w-100">
            CLEAR CACHE
          </li>
        </ul>
      </div>
      <FormattingBar/>
      <div class="main-content" id="layout-grid-content">
        <div class="container-fluid position-relative translation-list g-0">
          <div class="row g-0">
            <div class="col">
              <ul id="translation-tree-base">
                <TranslationTree
                    :translation="getSource"
                    language="english"/>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="loading"
         class="position-absolute d-flex justify-content-center align-items-center w-100 h-100
         flex-column loading-spinner">
      <fa icon="spinner" class="fa-2x spin mb-3"/>
      Loading
    </div>
  </div>
</template>
<script>
import ScrollSpy from 'simple-scrollspy';
import dot from 'dot-object';
import store from '@/store/lang';
import TranslationTree from '@/pages/translations/TranslationTree.vue';
import NavigationTree from '@/pages/translations/NavigationTree.vue';
import FormattingBar from '@/components/FormattingBar.vue';
import TranslationDropBox from '@/components/TranslationDropBox.vue';
import replaceIcons from '../../plugins/icons';

export default {
  name: 'Translations',
  components: {
    TranslationDropBox,
    FormattingBar,
    NavigationTree,
    TranslationTree,
  },
  data() {
    return {
      currentLang: '',
      store: store.state,
      mode: 'light',
      pendingFiles: [],
      formCreation: {
        locale: '',
        use_blank: false,
      },
      loading: false,
      translationType: 'json',
    };
  },
  mounted() {
    this.mode = this.getMode;

    this.translationType = localStorage.getItem('translationType') || 'json';
    this.store.editor.fileType = this.translationType;
    this.store.editor.theme = localStorage.getItem('mode') || 'light';
    this.store.editor.session = Boolean(localStorage.getItem('translationInSession')) || false;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);

      if (key.startsWith('translationFile')) {
        this.parseFile(key);
      }
    }

    this.currentLang = localStorage.getItem('translationLangCode');
  },
  unmounted() {

  },
  methods: {
    parseFile(translationKey) {
      let fileName = translationKey.match(/[a-z]{2}_[a-z]{2}/)[0];
      const langCode = fileName;
      const file = localStorage.getItem(translationKey);

      if (!file) {
        return;
      }

      if (fileName === 'en_gb') {
        fileName = 'english';
      } else if (fileName === 'source') {
        fileName = 'source';
      } else {
        fileName = 'locale';
      }

      const parsedFile = this.decodeFile(replaceIcons(file));

      this.store[fileName].source = file;
      this.store[fileName].decoded = parsedFile;
      this.store[fileName].cached = true;
      this.store[fileName].state = 'ready';
      this.store[fileName].lang = fileName;
      this.store[fileName].langCode = langCode;
    },
    decodeFile(file) {
      const translationType = this.store.editor.fileType.toString();
      let parsedFile = null;

      if (translationType === 'json') {
        parsedFile = JSON.parse(replaceIcons(file));
      } else if (translationType === 'lang') {
        parsedFile = this.parseIniFile(file);
      }

      return parsedFile;
    },
    parseIniFile(file) {
      const ex = file.split('\n');
      const obj = {};

      for (let i = 0; i < ex.length; i++) {
        if (ex[i]) {
          const split = ex[i].split('=');
          const key = split[0];
          const value = split[1];
          dot.str(key, value, obj);
        }
      }

      return obj;
    },
    addFile(file) {
      console.log('adding file');
      this.pendingFiles.push(file);
    },
    beginTranslation() {
      this.loading = true;

      // If no timeout then the interface will lag, nextTick() also will not work here fyi
      setTimeout(() => {
        this.readFiles();

        if (this.formCreation.use_blank) {
          this.createBlankLocale();
        }
        localStorage.setItem('translationInSession', 'true');
      });
    },
    createBlankLocale() {
      const blank = {};
      const langCode = this.formCreation.locale || 'it_it';

      this.store.locale.source = blank;
      this.store.locale.decoded = blank;
      this.store.locale.state = 'ready';
      this.store.locale.lang = 'locale';
      this.store.locale.langCode = langCode;
      this.store.locale.fileType = 'json';

      localStorage.setItem(`translationFile${langCode}`, '{}');
      localStorage.setItem('translationLangCode', langCode);
      localStorage.setItem('translationType', 'json');
    },
    validateKeys(source, target) {
      if (typeof source !== 'object' || typeof target !== 'object') {
        console.log('Failed to validate keys');
        console.log(typeof source, typeof target);
        return target;
      }
      const result = {};
      Object.keys(target).forEach((key) => {
        if (key in source) {
          const sourceValue = source[key];
          const targetValue = target[key];

          if (typeof sourceValue === 'object' && typeof targetValue === 'object' && sourceValue !== null && targetValue !== null) {
            // Recursive call for nested objects
            result[key] = this.validateKeys(sourceValue, targetValue);
          } else if (typeof sourceValue !== typeof targetValue) {
            // Types don't match; add _outdated to the key
            result[`${key}_outdated`] = targetValue;
          } else {
            // Types match; keep the key as is
            result[key] = targetValue;
          }
        }
      });
      return result;
    },
    clearValues(obj) {
      const keys = Object.keys(obj);
      for (let i = 0; i < keys.length; i++) {
        const point = obj[keys[i]];
        if (typeof point === 'object') {
          this.clearValues(point);
        } else {
          obj[keys[i]] = '';
        }
      }

      return obj;
    },
    readFiles() {
      let count = 0;

      if (this.pendingFiles.length === 0) {
        this.store.editor.ready = true;
        this.activateScrollSpy();
      }

      this.pendingFiles.forEach((file) => {
        count++;
        this.readFile(file);

        if (count === this.pendingFiles.length) {
          this.store.editor.ready = true;
          this.activateScrollSpy();
        }
      });
    },
    readFile(file) {
      const fileReader = new FileReader();

      fileReader.onload = (event) => {
        try {
          const rawFileName = file.name;
          const fileName = rawFileName.match(/[a-z]{2}_[a-z]{2}/)[0];
          const fileType = rawFileName.split('.')
            .slice(-1)[0];
          const fileContents = replaceIcons(event.target.result);

          localStorage.setItem(`translationFile${fileName}`, fileContents);

          const isSource = fileName === 'source' || fileName === 'en_gb';
          if (!isSource) {
            localStorage.setItem('translationLangCode', fileName);
          }

          localStorage.setItem('translationType', fileType);
          this.store.editor.fileType = fileType;
          this.parseFile(`translationFile${fileName}`);

          if ((isSource && this.store.locale.cached) || (!isSource && this.store.english.cached)) {
            const content = this.validateKeys(this.getSource, this.getLocale);
            this.store.locale.source = JSON.stringify(content);
            this.store.locale.decoded = content;
            localStorage.setItem(`translationFile${this.store.locale.langCode}`, this.store.locale.source);
          }
        } catch (e) {
          // yolo
        }
      };

      fileReader.readAsText(file);
    },
    clearCache() {
      const translationKeys = [];
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);

        if (key.startsWith('translation')) {
          translationKeys.push(key);
        }
      }
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < translationKeys.length; i++) {
        localStorage.removeItem(translationKeys[i]);
      }
      window.location.reload();
    },
    activateScrollSpy() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.loading = false;
          window.scrollSpy = ScrollSpy('#layout-grid-navigation', {
            sectionClass: '.translation-section',
            menuActiveTarget: 'a',
            offset: 100,
          });
        }, 500);
      });
    },
    setMode(mode) {
      localStorage.setItem('mode', mode);
      this.mode = mode;
      this.store.editor.theme = mode;
    },
    newTranslation() {
      this.clearCache();
    },
    continueTranslation() {
      this.beginTranslation();
    },
  },
  computed: {
    getSource() {
      return this.store.source.decoded || this.store.english.decoded;
    },
    getLocale() {
      return this.store.locale.decoded;
    },
    getMode() {
      return localStorage.getItem('mode') || 'light';
    },
    isReadyToTranslate() {
      return (['pending', 'ready'].includes(this.store.english.state)
          && (
            ['pending', 'ready'].includes(this.store.locale.state) || (this.formCreation.use_blank && this.formCreation.locale.length > 0)
          ));
    },
    getPossibleLangCodes() {
      return this.store.langCodes;
    },
    getLangCode() {
      return this.store.locale.langCode;
    },
    hasSession() {
      return this.store.editor.session;
    },
  },
};
</script>
